import './ImageSlider.css'

import UseWindowDimensions from '../../Hooks/UseWindowDimensions.js';

export default function ImageSlider({ home, products, wedding }) {
    const { width } = UseWindowDimensions();

    const imageCarousel = [
        {
            imageHome: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fmini-cakes%2FCell-Cake.png?alt=media&token=64e2e4dd-bd03-48f4-99f0-c389b54e3b7b",
            imageProducts: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgeneral-cakes%2FCell-Cake-Home.png?alt=media&token=c7eecb71-1ed2-485a-bb1d-1b64993459ca",
            imageWedding: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fwedding-cakes%2FCell-Cake-W.png?alt=media&token=6172ab9e-d55a-4d5c-8aea-bd26051516f9",
        },
        {
            imageHome: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fmini-cakes%2FCell-Cake-1.png?alt=media&token=500c3772-2bb1-4276-8762-361a74e17074",
            imageProducts: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgeneral-cakes%2FCell-Cake-1-Home.png?alt=media&token=406df1cc-1bc6-48f2-9809-9aab2dee9f82",
            imageWedding: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fwedding-cakes%2FCell-Cake-1-W.png?alt=media&token=285e4755-c0c7-4eca-9e88-56b07dbbc1db",
        },
        {
            imageHome: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fmini-cakes%2FCell-Cake-2.png?alt=media&token=d5e04772-33bf-42a1-9d19-a201d6e9ad7b",
            imageProducts: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgeneral-cakes%2FCell-Cake-2-Home.png?alt=media&token=b73087d4-bd36-41c6-adf0-2060389b4dac",
            imageWedding: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fwedding-cakes%2FCell-Cake-2-W.png?alt=media&token=a27d9a57-3066-406e-9a75-88f845c0ffc1",
        },
        {
            imageHome: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fmini-cakes%2FCell-Cake-3.png?alt=media&token=75539bec-88b8-4dfc-8d88-8a153d799120",
            imageProducts: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgeneral-cakes%2FCell-Cake-3-Home.png?alt=media&token=e1e51f64-a24e-408a-8d30-cd6c967e5d97",
            imageWedding: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fwedding-cakes%2FCell-Cake-3-W.png?alt=media&token=177ea625-1fcb-4d10-a9cc-50d37b7fb5fa",
        },
        {
            imageHome: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fmini-cakes%2FCell-Cake-4.png?alt=media&token=69cc9bba-f534-4a3e-83de-b1ac5b6a817a",
            imageProducts: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgeneral-cakes%2FCell-Cake-4-Home.png?alt=media&token=4ae8acda-e62e-41b4-ac05-34744da1f608",
            imageWedding: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fwedding-cakes%2FCell-Cake-4-W.png?alt=media&token=0b269942-234e-454d-85e2-f3d10b3c7696",
        },
        {
            imageHome: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fmini-cakes%2FCell-Cake-5.png?alt=media&token=76500ad5-5c2f-4161-8245-2a748ede85a8",
            imageProducts: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgeneral-cakes%2FCell-Cake-5-Home.png?alt=media&token=bf93aaa7-a8e8-4633-bf3c-b9f8d260dac2",
            imageWedding: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fwedding-cakes%2FCell-Cake-5-W.png?alt=media&token=d11682f0-5c30-4aa3-9539-f642afba66d2",
        }
    ]

    let cakeOneImageUrl;
    let cakeTwoImageUrl;
    let cakeThreeImageUrl;
    let cakeFourImageUrl;
    let cakeFiveImageUrl;
    let cakeSixImageUrl;

    if (home) {
        cakeOneImageUrl = imageCarousel[0].imageHome;
        cakeTwoImageUrl = imageCarousel[1].imageHome;
        cakeThreeImageUrl = imageCarousel[2].imageHome;
        cakeFourImageUrl = imageCarousel[3].imageHome;
        cakeFiveImageUrl = imageCarousel[4].imageHome;
        cakeSixImageUrl = imageCarousel[5].imageHome;
    } else if (products) {
        cakeOneImageUrl = imageCarousel[0].imageProducts;
        cakeTwoImageUrl = imageCarousel[1].imageProducts;
        cakeThreeImageUrl = imageCarousel[2].imageProducts;
        cakeFourImageUrl = imageCarousel[3].imageProducts;
        cakeFiveImageUrl = imageCarousel[4].imageProducts;
        cakeSixImageUrl = imageCarousel[5].imageProducts;
    } else if (wedding) {
        cakeOneImageUrl = imageCarousel[0].imageWedding;
        cakeTwoImageUrl = imageCarousel[1].imageWedding;
        cakeThreeImageUrl = imageCarousel[2].imageWedding;
        cakeFourImageUrl = imageCarousel[3].imageWedding;
        cakeFiveImageUrl = imageCarousel[4].imageWedding;
        cakeSixImageUrl = imageCarousel[5].imageWedding;
    } else {
        return (
            <div />
        )
    }

    if (width > 1200) {
        return (
            <div className="gallery-image-container">
                <div className="scroll-parent">
                    <div className="scroll-element primary">
                        <img alt="cake pastís" src={cakeOneImageUrl} />
                        <img alt="cake pastís" src={cakeTwoImageUrl} />
                        <img alt="cake pastís" src={cakeThreeImageUrl} />
                        <img alt="cake pastís" src={cakeFourImageUrl} />
                        <img alt="cake pastís" src={cakeFiveImageUrl} />
                        <img alt="cake pastís" src={cakeSixImageUrl} />
                    </div>
                    <div className="scroll-element secondary">
                        <img alt="cake pastís" src={cakeOneImageUrl} />
                        <img alt="cake pastís" src={cakeTwoImageUrl} />
                        <img alt="cake pastís" src={cakeThreeImageUrl} />
                        <img alt="cake pastís" src={cakeFourImageUrl} />
                        <img alt="cake pastís" src={cakeFiveImageUrl} />
                        <img alt="cake pastís" src={cakeSixImageUrl} />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="gallery-image-container--slider">
                <img alt="cake pastís" src={cakeOneImageUrl} className="cake-card" />
                <img alt="cake pastís" src={cakeTwoImageUrl} className="cake-card" />
                <img alt="cake pastís" src={cakeThreeImageUrl} className="cake-card" />
                <img alt="cake pastís" src={cakeFourImageUrl} className="cake-card" />
                <img alt="cake pastís" src={cakeFiveImageUrl} className="cake-card" />
                <img alt="cake pastís" src={cakeSixImageUrl} className="cake-card" />
            </div>
        )
    }
  }
  