import './Reviews.css'

import { useTranslation } from 'react-i18next';

export default function Reviews() {
    const { t } = useTranslation();

    return (
        <section className="reviews-section">
            <div className="container">
                <div className="tag">
                    <p className="caption">
                        {t("reviewsTag")}
                    </p>
                </div>
                <h1 className="title">
                    {t("reviewsTitle")}
                </h1>

                <div className="reviews-container">
                    <div className="review-container">
                        <div className="stars-container">
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                        </div>
                        <p>
                            Li vam fer l’encarrec del passtís del nostre casament. Molt amable, cuidant el detalls. Vam fer diverses proves fins a trobar el més adeqüat per nosaltres. Molta professionalitat i els nostres convidats contentissims. Sense dubte per repetir.
                        </p>
                    </div>
                    <div className="review-container">
                        <div className="stars-container">
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                        </div>
                        <p>
                            Els pastissos de crema cremada que ens va fer la Mireia per celebrar un aniversari, estaven boníssims!!
                        </p>
                    </div>
                    <div className="review-container">
                        <div className="stars-container">
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                        </div>
                        <p>
                            Avui hem tastat la mona de fruita i la de mantega amb la colla. Totes dos molt bones!!! Ens hem quedat amb ganes de tastar la de gema. Fins aviat, repetirem.
                        </p>
                    </div>
                    <div className="review-container">
                        <div className="stars-container">
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-full.svg" alt="" />
                            <img className="star star-full" src="images/icons/stars/star-empty.svg" alt="" />
                        </div>
                        <p>
                            Per la fira vam provar el braç de nata i el Lemon pie (pastís de llimona i merengue), estaven boníssims. Enhorabona Mireia
                        </p>
                    </div>
                </div>

                <div className="see-all-reviews-container">
                    <a 
                        href="https://www.google.com/maps/place/Pastisseria+La+Gormanda/@41.3643799,1.2876761,17z/data=!4m8!3m7!1s0x12a40544efa899d7:0xb01c245c4fbe3dbc!8m2!3d41.3643799!4d1.290251!9m1!1b1!16s%2Fg%2F11tdc503c_?entry=ttu" 
                        className="button-primary button">
                            {t("reviewsTag")}
                    </a>
                </div>
            </div>
        </section>
    )
  }
  