import './Contact.css'
import '../../Styles/style.css'

import Header from '../../Components/Header/Header.js'
import Footer from '../../Components/Footer/Footer.js'

import React from 'react';
import { getDatabase, ref, set } from "firebase/database";
import { v4 as uuidv4 } from 'uuid';
import validator from "validator";
import i18n from 'i18next';

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            message: "", 
            email: "", 
            errorMessage: "", 
            successMessage: "",
            translationsLoaded: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.translationsLoaded !== prevState.translationsLoaded) {
          return ({ translationsLoaded: nextProps.translationsLoaded }) // <- this is setState equivalent
        }
        return null
      }

    handleEmailChange = (e) => {
        this.setState( { email: e.target.value } );

        if (!(e.target.value.includes("@") && e.target.value.includes("."))) {
            return
        }

        if (!validator.isEmail(e.target.value)) {
            this.setState( { errorMessage: "El correu no és vàlid", successMessage: "" } );
        } else {
            this.setState( { errorMessage: "", successMessage: "" } );
        }
    }

    handleMessageChange = (e) => {
        this.setState( { message: e.target.value, successMessage: "" } );
    }

    sendEmail = () => {
        if (!validator.isEmail(this.state.email)) {
            this.setState( { errorMessage: "El correu no és vàlid" } );
            return
        } else {
            this.setState( { errorMessage: "" } );
        }

        if (this.state.message === "") {
            this.setState( { errorMessage: "El missatge està buit" } );
            return
        } else {
            this.setState( { errorMessage: "" } );
        }

        const database = getDatabase();
        const communicationId = uuidv4();
        const email = this.state.email
        const message = this.state.message

        set(ref(database, 'mails/' + communicationId), {
          email: email,
          message: message,
          forwarded: false
        });

        this.setState({ 
            successMessage: "Hem rebut la vostra peticiò, ens ficarem en contacte l'abans possible." ,
            errorMessage: "",
            email: "",
            message: ""
        });
    }

    render() {
        if (this.state.translationsLoaded) {
            return (
                <div className='Contact'>
                    <Header />
        
                    <section className="contact-section first-section">
                        <div className="container">
                            <div className="tag">
                                <p className="caption">
                                    {i18n.t("contactFormTag")}
                                </p>
                            </div>
                            <h1 className="title">
                                {i18n.t("contactFormTitle")}
                            </h1>
                            <form id="contact-form" className='contact-form'>
                                <label>{i18n.t("contactFormEmailLabel")}</label>
                                <input type="email" id="email" name="email" value={this.state.email} onChange={this.handleEmailChange} required />

                                <label>{i18n.t("contactFormMessageLabel")}</label>
                                <textarea id="message-text" name="message" rows="5" value={this.state.message} onChange={this.handleMessageChange} required />

                                <p className='error-message'>{this.state.errorMessage}</p>
                                <button onClick={this.sendEmail} type="button" className="button-primary button" id="submit">{i18n.t("contactFormSendButton")}</button>
                                <p className='success-message'>{this.state.successMessage}</p>
                            </form>
                        </div>
                    </section>

                    <section className="location-contact-section">
                        <div className="container">
                            <h1 className="title">
                                {i18n.t("contactWhereToFindUsTitle")}
                            </h1>
                            <div className="location-information-container">
                                <div className="location-information-description">
                                    <p>
                                        {i18n.t("contactWhereToFindUsText_1")}
                                        <br/><br/>
                                        {i18n.t("contactWhereToFindUsText_2")}
                                    </p>
                                    <div className="location-buttons-container">
                                        <a href="tel:+34679110663" className="button-primary button">{i18n.t("contactWhereToFindUsButton")}</a>
                                        <a href="https://wa.me/34679110663" className="button-primary button">WhatsApp</a>
                                    </div>
                                </div>
                                <iframe 
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2994.446643077328!2d1.288062315912972!3d41.36437987926628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a40544efa899d7%3A0xb01c245c4fbe3dbc!2sPastisseria%20La%20Gormanda!5e0!3m2!1sen!2sch!4v1681131233153!5m2!1sen!2sch" 
                                    allowFullScreen="" 
                                    loading="lazy"
                                    title="maps"
                                />
                            </div>
                        </div>
                    </section>
                        
                    <section className="images-contact-section">
                        <div>
                            <div className="store-image-carousel">
                                <img
                                    className="location-section-image"
                                    src="https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/LA%20GORMANDA-81.JPG?alt=media&token=e690703b-8eee-42c8-9f57-1e536ce64098" 
                                    alt="gormanda botiga mireia pastisseria"
                                />

                                <img
                                    className="location-section-image"
                                    src="https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/LA%20GORMANDA-92.JPG?alt=media&token=8192080a-dabd-4000-8281-ed0deb34a861" 
                                    alt="gormanda botiga mireia pastisseria"
                                />

                                <img
                                    className="location-section-image"
                                    src="https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/LA%20GORMANDA-87.JPG?alt=media&token=48013c1a-9e0d-4587-831b-c0a4c16dc03b" 
                                    alt="gormanda botiga mireia pastisseria"
                                />

                                <img
                                    className="location-section-image"
                                    src="https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/LA%20GORMANDA-93.JPG?alt=media&token=77d9e8ce-2ec4-4858-8b4b-12bf131465fc" 
                                    alt="gormanda botiga mireia pastisseria"
                                />
                            </div>
                        </div>
                    </section>

                    <Footer />
                </div>
            );
        } else {
            return (
                <div className='Contact'>
                    <Header />
                    <div className="contact-empty-fullscreen"/>
                    <Footer />
                </div>
            )
        }
    }
}
  