import './NavItem.css'
import { Link } from "react-router-dom";

export default function NavItem({ title, active, path }) {
    if (active) {
        return (
            <li className="nav-item">
                <Link className="nav-link active" to={path}>{title}</Link>
            </li>
        )
    } else {
        return (
            <li className="nav-item">
                <Link className="nav-link" to={path}>{title}</Link>
            </li>
        )
    }
    
  }
  