import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from 'js-cookie';
import Backend from 'i18next-http-backend';
import Cache from 'i18next-localstorage-cache';

import Home from './Pages/Home/Home.js';
import Products from './Pages/Products/Products.js';
import Weddings from './Pages/Weddings/Weddings.js';
import Contact from './Pages/Contact/Contact.js';

import ScrollToTop from './Hooks/ScrollToTop.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

class App extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      translationsLoaded: false
    };

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: "AIzaSyAd8bpoHCU4ZIME3bJ8KIE-97dwH8x0Wo8",
      authDomain: "gormanda-5662e.firebaseapp.com",
      projectId: "gormanda-5662e",
      storageBucket: "gormanda-5662e.appspot.com",
      messagingSenderId: "548978042487",
      appId: "1:548978042487:web:4cf8df219fa6ee764f0872",
      measurementId: "G-2MK3H33ZJK",
      databaseURL: "https://gormanda-5662e-default-rtdb.europe-west1.firebasedatabase.app/"
    };
  
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider('6Lc92gYqAAAAAJ4G9vvnyrLfqv_oeFHsh_e5fbKK'),
      isTokenAutoRefreshEnabled: true
    });

    const languageDetector = new LanguageDetector();
    languageDetector.addDetector({
      name: 'cookie',
      lookup(options) {
        return Cookies.get('lang');
      },
    });

    // Initialize Analytics and get a reference to the service
    const analytics = getAnalytics(app);

    i18n
      .use(Backend)
      .use(languageDetector)
      .use(initReactI18next)
      .use(Cache)
      .init({
        fallbackLng: "ca",
        react: {
          wait: true,
          useSuspense: false,
        },
        debug: false,
        keySeparator: false,
        interpolation: {
          escapeValue: false,
        },
        backend: {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      }, (err, t) => {
        this.setState( { translationsLoaded: true } );
      });
  }

  render() {
      return (
        <React.StrictMode>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route index element={<Home />} />
              <Route path="carta" element={<Products />} />
              <Route path="bodes" element={<Weddings />} />
              <Route path="nosaltres" element={<Contact translationsLoaded={this.state.translationsLoaded}/>} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </BrowserRouter>
        </React.StrictMode>
      );
  }
}

root.render(
  <App />
);