import './Home.css';
import '../../Styles/style.css';

import Footer from '../../Components/Footer/Footer.js'
import Header from '../../Components/Header/Header.js'
import Reviews from '../../Components/Reviews/Reviews.js'
import CakeMenu from '../../Components/CakeMenu/CakeMenu.js'
import ImageSlider from '../../Components/ImageSlider/ImageSlider.js'

import { useTranslation } from 'react-i18next';

import { Link } from "react-router-dom";

function Home() {
    const { t } = useTranslation();

    return (
        <div className="Home">
            <Header />

            <section className="main-hero-section">
                <div className="container">
                    <div className="main-hero-content-container">
                        <div className="main-hero-text-container">
                            <h1 className="title">
                                La Gormanda
                            </h1>
                            <div className="description">
                                <p>
                                {t("welcomeHomeText_1")}
                                </p>
                                <p>
                                {t("welcomeHomeText_2")}
                                </p>
                            </div>
                            <div className="main-hero-buttons-container">
                                <a href="tel:+34679110663" className="button-secondary button">{t("welcomeHomeButtonCall")}</a>
                                <a href="#cakes-section-id" className="button-secondary button">{t("welcomeHomeButtonSeeAll")}</a>
                            </div>
                        </div>
                        <img 
                        className='main-hero-content-image'
                            src="https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/LA%20GORMANDA-23.JPG?alt=media&token=c0802a07-b5a2-4545-a90a-721c6076aaae" 
                            alt="natural gormanda qualitat pastissera" 
                        />
                    </div>
                </div>
            </section>

            <CakeMenu 
                tag={t("discoverTag")}
                title={t("discoverTitle")}
                description={t("discoverText")}
                seeAllButtonPath={"/carta"}
            />

            <div className="icons-container">
                <div className="icon-container">
                    <img className="icon" src="images/icons/passion.svg" alt="passio natural gormanda" />
                    <h2>{t("valueTitle_1")}</h2>
                    <p>{t("valueText_1")}</p>
                </div>
                <div className="icon-container">
                    <img className="icon" src="images/icons/quality.svg" alt="qualitat natural gormanda" />
                    <h2>{t("valueTitle_2")}</h2>
                    <p>{t("valueText_2")}</p>
                </div>
                <div className="icon-container">
                    <img className="icon" src="images/icons/location.svg" alt="personalitat natural gormanda" />
                    <h2>{t("valueTitle_3")}</h2>
                    <p>{t("valueText_3")}</p>
                </div>
            </div>

            <ImageSlider 
                home={true}
                products={false} 
                wedding={false}
            />

            <section className="wedding-section">
                <div className="container">
                    <div className="tag">
                        <p className="caption">
                            {t("discoverWeddingTag")}
                        </p>
                    </div>
                    <h1 className="title">
                        {t("discoverWeddingTitle")}
                    </h1>
                    <div className="wedding-image-container">
                        <img src="https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/LA%20GORMANDA-14.JPG?alt=media&token=c00da003-cf77-42ec-b8f1-d8da2d54038f" alt="boda gormanda qualitat" />
                        <div className="wedding-description-container description">
                            <p>{t("discoverWeddingText")}</p>
                            <Link 
                                to="/bodes"
                                className="button-secondary button">
                                {t("discoverWeddingButtonMore")}
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <Reviews />

            <Footer />
        </div>
    );
}

export default Home;
