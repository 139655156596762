import UseWindowDimensions from '../../Hooks/UseWindowDimensions.js';
import './CakeMain.css'

import { useTranslation } from 'react-i18next';

export default function CakeMain() { 
    const { width } = UseWindowDimensions();
    const { t } = useTranslation();

    if (width > 1200) {
        return (
            <section className="hero-section-cake-main">
                <div className="container">
                    <div className="tag">
                        <p className="caption">
                            {t("cakeRecommendedTag")}
                        </p>
                    </div>
                    <h1 className="title">
                        {t("cakeRecommendedTitle")}
                    </h1>
                    <div id="hero-section-cake-description-container">
                        <div className="recommended-pie-container">
                            <div className="recommended-pie-description">
                            <div className="cake-item-container-recommended">
                                <div className="margin-left-recommended">
                                    <div className="cake-title-recommended">
                                        <h4>Lemon Pie</h4>
                                    </div>
                                    <div className="cake-description-recommended">
                                        <p>{t("cakeRecommendedText")}</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="recommended-pie-image-container">
                            <img 
                                className="recommended-pie-image" 
                                src="https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/LA%20GORMANDA-18.png?alt=media&amp;token=5da67983-5135-42ec-a6f9-c9b0c861d111" 
                                alt="natural gormanda qualitat pastissera lemon-pie"
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    } else {
        return (
            <section className="hero-section-cake-main">
                <div className="container">
                    <div className="tag">
                        <p className="caption">
                            {t("cakeRecommendedTag")}
                        </p>
                    </div>
                    <h1 className="title">
                        {t("cakeRecommendedTitle")}
                    </h1>
                    <div id="hero-section-cake-description-container">
                        <div className="cake-item-container-recommended-mobile">
                            <div className="cake-title-image-recommended">
                                <img 
                                    src="https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/LA%20GORMANDA-19.JPG?alt=media&amp;token=752d4a13-eab2-4459-9336-fed978f954d7" 
                                    alt="natural gormanda qualitat pastissera"
                                />
                                <div className="cake-description-recommended">
                                    <h4>Lemon Pie</h4>
                                    <p>{t("cakeRecommendedText")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}