import './Footer.css'

import Spacer from "../Spacer/Spacer.js";
import LanguageSelector from './../LanguageSelector/LanguageSelector.js'

export default function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="footer-content-container">
                    <img 
                        className="footer-logo-image" 
                        alt=""
                        src="../images/logo-square.svg"
                    />
                    <Spacer />
                    <div className="footer-contact-container">
                        <div className="footer-contact">
                            <p>
                                Carrer 11 de Setembre, 2
                            </p>
                            <p>
                                43810 El Pla de Santa Maria (Tarragona)
                            </p>
                            <a href="tel:+34679110663">679 110 663</a>
                            <a href="mailto:info@lagormandapastisseria.com">info@lagormandapastisseria.com</a>
                        </div>
                    </div>
                    <Spacer />
                    <div className="instagram-language-container">
                        <a href="https://www.instagram.com/pastisseria_la_gormanda">
                            <img 
                                className="footer-instagram-logo"
                                alt=""
                                src="../images/icons/instagram_white.svg"
                            />
                        </a>
                        <a href="https://wa.me/34679110663">
                            <img 
                                className="footer-whatsapp-logo"
                                alt=""
                                src="../images/icons/whatsapp_white.svg"
                            />
                        </a>
                        <LanguageSelector />
                    </div>
                </div>
            </div>
        </footer>
    )
}