import './Header.css'
import { Outlet } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import NavItem from '../NavItem/NavItem';
import Spacer from './../Spacer/Spacer.js'

export default function Header() {
    const currentPath = window.location.pathname;

    const mainPath = "/"
    const weddingsPath = "/bodes"
    const productsPath = "/carta"
    const contactPath = "/nosaltres"

    const { t } = useTranslation();

    return (
        <header>
            <nav className="navbar">
                <img className="logo" src="images/logo.png" alt="logo gormanda mireia pastisseria" />
                <Spacer />
                <ul className="nav-list">
                    <NavItem 
                        title={t('navigationHome')}
                        active={mainPath === currentPath}
                        path={mainPath}
                    />

                    <NavItem 
                        title={t('navigationProducts')}
                        active={productsPath === currentPath}
                        path={productsPath}
                    />

                    <NavItem 
                        title={t('navigationWeddings')}
                        active={weddingsPath === currentPath}
                        path={weddingsPath}
                    />

                    <NavItem 
                        title={t('navigationAboutUs')}
                        active={contactPath === currentPath}
                        path={contactPath}
                    />
                </ul>
            </nav>
            <Outlet />
        </header>
    )
  }
  