import './Title.css'

export default function Title({tag, title}) {
    return (
        <div className='title-tag-container'>
            <div className="tag">
                    <p className="caption">
                        {tag}
                    </p>
                </div>
                <h1 className="title">
                    {title}
                </h1>
        </div>
    );
}       