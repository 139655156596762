import './CakeCustomItem.css'

export default function CakeCustomItem({ title, titleOptions, flavours, decorations }) {
    let titleOptionsComponent;

    if (titleOptions === null && titleOptions.count === 0) {
        titleOptionsComponent = (<div />);
    } else {
        titleOptionsComponent = (
            titleOptions.map(function(titleOption){
                return (
                    <li key={titleOption}>{titleOption}</li>
                );
            })
        )
    }

    return (
        <div className="custom-cake-item-container">
            <div className="step-container">
                <p className="step-container-content-descriptor">
                    1. BASE
                </p>
                <h3>
                    {title}
                </h3>
                <ul>
                    {titleOptionsComponent}
                </ul>
                <div className="custom-cake-bottom-margin"/>
            </div>
            
            <div className="vertical-breaker"/>
            
            <div className="step-container">
                <p className="step-container-content-descriptor">
                    2. GUST
                </p>
                <ul>
                {
                    flavours.map(function(flavour){
                        return (
                            <li key={flavour}>{flavour}</li>
                        );
                    })
                }
                </ul>
                <div className="custom-cake-bottom-margin"/>
            </div>
            
            <div className="vertical-breaker"/>
            
            <div className="step-container">
                <p className="step-container-content-descriptor">
                    3. DECORACIÓ
                </p>
                <ul>
                {
                    decorations.map(function(decoration){
                        return (
                            <li key={decoration}>{decoration}</li>
                        );
                    })
                }
                </ul>
            </div>
        </div>
    );
}