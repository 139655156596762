import './Weddings.css'
import '../../Styles/style.css'
import '../../Styles/colors.css'

import Header from '../../Components/Header/Header.js'
import Footer from '../../Components/Footer/Footer.js'
import ImageSlider from '../../Components/ImageSlider/ImageSlider.js'

import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Weddings() {
    const { t } = useTranslation();

    return (
        <div className='Weddings'>
            <Header />

            <section className="hero-section">
                <div className="container">
                    <div className="hero-content-container">
                        <div className="wedding-text-container">
                            <h1 className="title">
                                {t("weddingWelcomeTitle")}
                            </h1>
                            <div className="description">
                                <p>
                                    {t("weddingWelcomeText_1")}                                    
                                    <br/><br/>
                                    {t(("weddingWelcomeText_2"))}
                                    <br/><br/>
                                    {t("weddingWelcomeText_3")}
                                </p>
                            </div>
                        </div>
                        <img 
                            src="https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/wedding-hero.png?alt=media&token=9857483a-60e4-4d80-9775-04304b3a88af" 
                            alt="boda estil natural gormanda qualitat pastissera"
                        />
                    </div>
                </div>
            </section>

            <section className="wedding-catalog-section">
                <div className="container">
                    <div className="tag">
                        <p className="caption">
                            {t("weddingCatalogTag")}
                        </p>
                    </div>
                    <h1 className="title">
                        {t("weddingCatalogTitle")}
                    </h1>
                    <div className="description">
                        <p>
                            {t("weddingCatalogText")}
                        </p>
                    </div>

                    <ImageSlider 
                        home={false}
                        products={false} 
                        wedding={true}
                    />

                    <div className="icons-container">
                        <div className="icon-container">
                            <img className="icon" src="../images/icons/paint-brush.svg" alt="passio natural gormanda"/>
                            <h2>{t("weddingAddedValueTitle_1")}</h2>
                            <p>{t("weddingAddedValueText_1")}</p>
                        </div>
                        <div className="icon-container">
                            <img className="icon" src="../images/icons/car.svg" alt="qualitat natural gormanda"/>
                            <h2>{t("weddingAddedValueTitle_2")}</h2>
                            <p>{t("weddingAddedValueText_2")}</p>
                        </div>
                        <div className="icon-container">
                            <img className="icon" src="../images/icons/dish.svg" alt="personalitat natural gormanda"/>
                            <h2>{t("weddingAddedValueTitle_3")}</h2>
                            <p>{t("weddingAddedValueText_3")}</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bakery-master-section">
                <div className="container">
                    <div className="tag">
                        <p className="caption">
                            {t("weddingIdeaRealityTag")}
                        </p>
                    </div>
                    <h1 className="title">
                        {t("weddingIdeaRealityTitle")}
                    </h1>
                    <div className="bakery-master-image-container">
                        <img 
                            src="https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/LA%20GORMANDA-47.JPG?alt=media&token=7e415976-3686-42b6-8cbd-d2b0a7f7923d" 
                            alt="boda gormanda qualitat"
                        />
                        <div className="bakery-master-description-container description">
                            <p>
                                {t("weddingIdeaRealityText_1")}
                                <br/><br/>
                                {t("weddingIdeaRealityText_2")}
                            </p>
                            <Link 
                                to="/nosaltres"
                                className="button-secondary button">
                                {t("weddingIdeaRealityButton")}
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
  }
  