import './Products.css'
import '../../Styles/style.css'

import Header from '../../Components/Header/Header.js'
import Footer from '../../Components/Footer/Footer.js'
import Reviews from '../../Components/Reviews/Reviews.js'
import CakeMenu from '../../Components/CakeMenu/CakeMenu.js'
import CakeMain from '../../Components/CakeMain/CakeMain.js'
import Gallery from '../../Components/Gallery/Gallery.js'
import ImageSlider from '../../Components/ImageSlider/ImageSlider.js'
import CakeCustomMenu from '../../Components/CakeCustomMenu/CakeCustomMenu.js'

export default function Products() {
    return (
        <div className='Products'>
            <Header />

            <div className="first-section" >
                <CakeMain />
            </div>

            <ImageSlider 
                home={false}
                products={true} 
                wedding={false}
            />

            <CakeMenu 
                tag={"carta de celebracions"}
                title={"Tenim el que busques per al teu dia especial"}
                description={" "}
                seeAllButtonPath={null}
            />

            <CakeCustomMenu />

            <Gallery />

            <Reviews />

            <Footer />
        </div>
    )
  }