import './CakeIconItem.css'
import Spacer from './../Spacer/Spacer.js'

export default function CakeIconItem({cakeTitle, cakeDescription, tag, cakeIcon }) {
    return (
        <li>
            <div className="cake-item-icon-container">
                <img alt={cakeTitle} className="cake-item-icon-image" src={"../images/cake-icons/" + cakeIcon}/>
                <div className="cake-item-icon-description-container">
                    <div className="cake-item-icon-title">
                        <h4>{cakeTitle}</h4>
                    </div>
                    <div className="cake-item-icon-description">
                        <p>{cakeDescription}</p>
                    </div>
                    <Spacer />
                    <div className="cake-item-icon-tag-container">
                        <hr/>
                        <p>{tag}</p>
                    </div>
                </div>
            </div>
        </li>
    );
}